import React, { useEffect, useState, useMemo } from "react";
import Cookies from "universal-cookie";
import "../../../stylesheets/tables.scss";
import { NavLink, useSearchParams } from "react-router-dom";
import { FaAngleDown, FaAngleUp, FaCheck, FaExclamation, FaComment } from "react-icons/fa";


const cookies = new Cookies(null, { path: "/" });

const Circle = ({ color }) => {
  return (
    <span
      style={{
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: `${color}`,
        display: "inline-block",
        marginRight: "6px",
        position: "relative",
        top: "-2px",
        left: "0",
      }}
    ></span>
  );
};

const toTitleCase = (str) => {
  return str
    .split(" ")
    .map((word) => {
      if (word.toUpperCase() === "AFB") {
        return word.toUpperCase(); // Keep "AFB" fully capitalized
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

const RenderBillString = ({ inputArray }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const array = inputArray?.split(",");

  let result = "";

  if (!array || array.length === 0) {
    result = "";
  } else if (array.length === 1) {
    result = array[0];
  } else {
    const firstTwo = array.slice(0, 2).join(", ");
    const rest = array.slice(2).join(", ");

    result = (
      <>
        {firstTwo}
        {array.length > 2 && (
          <>
            <span
              className="extra-containers"
              onClick={handleToggle}
              style={{ cursor: 'pointer' }}
            >
              {isExpanded ? " close" : ` +${array.length - 2}`}
            </span>
            {isExpanded && (
              <span className="rest" style={{ display: 'inline' }}>
                , {rest}
              </span>
            )}
          </>
        )}
      </>
    );
  }

  return <p>{result}</p>;
};

const statusIcon = (status) => {
  switch (status.toLowerCase()) {
    case "released":
      return (
        <>
          <Circle color="#23B574" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {status.toLowerCase()}
          </span>
        </>
      );
    case "not cleared by afb":
      return (
        <>
          <Circle color="#FF623E" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {toTitleCase(status)}
          </span>
        </>
      );
    case "assembly":
      return (
        <>
          <Circle color="#DDDD32" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {status.toLowerCase()}
          </span>
        </>
      );
    case "pending submission":
      return (
        <>
          <Circle color="#FFC0CB" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {status.toLowerCase()}
          </span>
        </>
      );
    case "admissible":
      return (
        <>
          <Circle color="#44C3C3" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {status.toLowerCase()}
          </span>
        </>
      );
    case "it shipment":
      return (
        <>
          <Circle color="#008B8B" />
          <p
            style={{
              display: "inline-block",
            }}
          >
            {status
              .toLowerCase()
              .split(" ")
              .map((i) => i[0].toUpperCase() + i.substring(1))
              .join(" ")
              .replace("It", "IT")}
          </p>
        </>
      );
    case "cbp intensive exam":
    case "cbp vax exam":
    case "cbp vacis exam":
    case "cbp docs review hold":
    case "isf hold":
      return (
        <>
          <Circle color="#3C45C8" />
          <p
            style={{
              display: "inline-block",
            }}
          >
            {status
              .toLowerCase()
              .split(" ")
              .map((i) => i[0].toUpperCase() + i.substring(1))
              .join(" ")
              .replace("Cbp", "CBP")
              .replace("Isf", "ISF")}
          </p>
        </>
      );
    case "submitted":
      return (
        <>
          <Circle color="#77C4FF" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            Awaiting Arrival
          </span>
        </>
      );
    case "pending documents":
      return (
        <>
          <Circle color="#EB1B23" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            Documents Required
          </span>
        </>
      );
    case "admissible":
    case "released":
    case "not cleared by afb":
      return (
        <>
          <Circle color="#FF623E" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            {toTitleCase(status)}
          </span>
        </>
      );

    case "accepted":
    case "liquidated":
      return (
        <>
          <Circle color="#2336B5" />
          <span
            style={{
              textTransform: "capitalize",
              display: "inline-block",
            }}
          >
            Other
          </span>
        </>
      );
    default:
      return <span>{status.toLowerCase()}</span>;
  }
};

export const GetContainers = ({ containers }) => {
  const [contOpen, setContOpen] = useState(false);
  let form = [];
  if (containers == null) {
    return null;
  }
  if (containers != null) {
    containers.split(",").forEach((c) => {
      form.push(c);
    });
    if (form.length > 2) {
      let firstThree = `${form[0]}, ${form[1]} `;
      let x = (
        <span
          className="extra-containers"
          onClick={() => {
            contOpen ? setContOpen(false) : setContOpen(true);
          }}
        >
          {contOpen ? "close" : `+${form.length - 1}`}
        </span>
      );
      let rest = "";
      for (let i = 2; i < form.length; i++) {
        rest += form[i] + ", ";
      }
      return (
        <p>
          <span
            className="rest"
            style={contOpen ? { display: "flex" } : { display: "none" }}
          >
            {rest}
          </span>
          {firstThree}
          {x}
        </p>
      );
    } else {
      return <p>{containers.replace(",", ", ")}</p>;
    }
  } else {
    return containers;
  }
};

const fixedFormat = (date) => {
  let year = "";
  let month = "";
  let day = "";
  for (let i = 0; i <= 3; i++) {
    year += date[i];
  }
  for (let i = 5; i <= 6; i++) {
    month += date[i];
  }
  for (let i = 8; i <= 9; i++) {
    day += date[i];
  }

  let form = `${month}/${day}/${year}`;
  return form;
};

export const ShipmentsTable = ({
  shipments,
  onIdArrayChange,
  toggleSelectAll,
  deletedShipments,
}) => {
  const [shipmentIdArray, setShimentIdArray] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [searchParams] = useSearchParams();
  const selectShipment = (id, target) => {
    const element = target.tagName == "DIV" ? target : target.parentElement;

    const found =
      shipmentIdArray.find((element) => element === id) == undefined
        ? true
        : false;

    if (element.dataset.checked == "false" && found) {
      setShimentIdArray((prevArray) => [...prevArray, parseInt(id)]);
    } else if (element.dataset.checked == "true" && !found) {
      setShimentIdArray((prevArray) =>
        prevArray.filter((value) => value !== parseInt(id))
      );
    }
  };

  const hasFilterParams = searchParams.get("status") || searchParams.get("custom") || searchParams.get("filter");

  const sortedShipmentData = useMemo(() => {
    if (shipments && shipments.length > 0) {
      const sortedCopy = [...shipments].sort((a, b) => new Date(a.est_time_arrive) - new Date(b.est_time_arrive));
      return sortedCopy.map((shipment) => ({
        id: shipment.id,
        status: shipment.status,
      }));
    }
    return [];
  }, [shipments]);

  const sortedShipmentIds = sortedShipmentData.map(({ id }) => id);

  const shouldFilterBeActive = !!hasFilterParams;

  const getUserRole = () => {
    const localRole = cookies.get("role");
    setUserRole(localRole);
  };

  const toggleSelectShipments = (state) => {
    let temp_id_arr = [];
    if (state == true) {
      shipments.forEach((ship) => {
        temp_id_arr.push(ship?.id);
      });
    } else if (state == false) {
      temp_id_arr = [];
    }

    setShimentIdArray(temp_id_arr);
  };

  useEffect(() => {
    onIdArrayChange(shipmentIdArray);
    getUserRole();
  }, [shipmentIdArray]);

  useEffect(() => {
    if (deletedShipments?.length > 0) {
      setShimentIdArray([]);
    }
  }, [deletedShipments]);
  useEffect(() => {
    const toggleAll = () => {
      toggleSelectShipments(toggleSelectAll);
    };

    toggleAll();
  }, [toggleSelectAll]);

  const isDuplicateRoute = 
  searchParams.get("custom") === "duplicates" && 
  searchParams.get("home") === "1";

  return (
    <tbody>
      {shipments.map((shipment, i) => {
        const isChecked =
          shipmentIdArray.find((element) => element === shipment?.id) !== undefined;
        const isHighlighted =
          shipment?.transport_mode?.code === 40 || shipment?.transport_mode?.code === 41;
  
        // Render the regular row
        const renderRow = (
          <tr key={`shipment-${i}`} className={`table-row ${isChecked ? 'checked' : ''}`}>
            {userRole == 1 ? (
              <td className="select_shipment">
                <div
                  className="checkbox"
                  data-checked={isChecked ? true : false}
                  onClick={(e) => {
                    selectShipment(shipment?.id, e.target);
                  }}
                >
                  {isChecked ? <p className="checked-item">✓</p> : ""}
                </div>
              </td>
            ) : (
              <></>
            )}
            <td className={`file_no ${isHighlighted ? 'highlighted' : ''}`}>
              <NavLink
                to={`/shipments/edit/${shipment?.id}`}
                state={{
                  filteredShipmentIds: sortedShipmentIds,
                  filter: shouldFilterBeActive,
                }}
              >
                {shipment?.file_number}
              </NavLink>
              {shipment?.comments && shipment.comments.length > 0 && (
                <div className="tooltip-container">
                  <FaComment className="tooltip-icon" />
                  <div className="tooltip">
                    {shipment.comments.map((comment, index) => (
                      <div key={index}>{comment.comment}</div>
                    ))}
                  </div>
                </div>
              )}
            </td>
            <td className={`master_bill ${isHighlighted ? 'highlighted' : ''}`}>
              <RenderBillString inputArray={shipment?.master_bill_lading} />
            </td>
            <td className={`house_bill ${isHighlighted ? 'highlighted' : ''}`}>
              <RenderBillString inputArray={shipment?.house_bill_lading} />
            </td>
            <td className={`containers ${isHighlighted ? 'highlighted' : ''}`}>
              <GetContainers containers={shipment?.containers} />
            </td>
            <td className={`reference_no ${isHighlighted ? 'highlighted' : ''}`}>
              <p>{shipment?.customer_reference_no}</p>
            </td>
            <td className={`discharge ${isHighlighted ? 'highlighted' : ''}`}>
              <p>{fixedFormat(shipment?.est_time_arrive)}</p>
            </td>
            <td className={`account_no ${isHighlighted ? 'highlighted' : ''}`}>
              <p>{shipment?.account_no}</p>
            </td>
            <td className={`fda_status ${isHighlighted ? 'highlighted' : ''}`}>
              {shipment?.fda_review === "MAY PROCEED" || shipment?.fda_review === null ? (
                <></>
              ) : (
                <FaExclamation className="fail" />
              )}
            </td>
            <td className={`status ${isHighlighted ? 'highlighted' : ''}`}>
              {statusIcon(shipment.status)}
            </td>
          </tr>
        );
  
        // Add a jump line every two shipments if on duplicates route
        const renderJumpLine =
          isDuplicateRoute && (i + 1) % 2 === 0 ? (
            <tr key={`jump-line-${i}`} className="jump-line">
              <td colSpan="10">
                <div className="jump-line-content">--- End of Pair ---</div>
              </td>
            </tr>
          ) : null;
  
        return (
          <>
            {renderRow}
            {renderJumpLine}
          </>
        );
      })}
    </tbody>
  );
  
};

export const ShipmentsTableSearch = ({ shipments, order, order_value }) => {
  return (
    <table>
      <thead className="head">
        <tr>
          <th
            onClick={() => {
              order_value === "0,0" ? order("1,0") : order("0,0");
            }}
          >
            Entry Number
            <span>
              {order_value === "1,0" ? <FaAngleUp /> : <FaAngleDown />}
            </span>
          </th>
          <th
            onClick={() => {
              order_value === "0,7" ? order("1,7") : order("0,7");
            }}
          >
            Master Bill of Lading
            <span>
              {order_value === "1,7" ? <FaAngleUp /> : <FaAngleDown />}
            </span>
          </th>
          <th
            onClick={() => {
              order_value === "0,8" ? order("1,8") : order("0,8");
            }}
          >
            House Bill of Lading
            <span>
              {order_value === "1,8" ? <FaAngleUp /> : <FaAngleDown />}
            </span>
          </th>
          <th
            onClick={() => {
              order_value === "0,1" ? order("1,1") : order("0,1");
            }}
          >
            Container Number(s)
            <span>
              {order_value === "1,1" ? <FaAngleUp /> : <FaAngleDown />}
            </span>
          </th>
          <th
            onClick={() => {
              order_value === "0,2" ? order("1,2") : order("0,2");
            }}
          >
            Reference Number
            <span>
              {order_value === "1,2" ? <FaAngleUp /> : <FaAngleDown />}
            </span>
          </th>
          <th
            onClick={() => {
              order_value === "0,4" ? order("1,4") : order("0,4");
            }}
          >
            ETA
            <span>
              {order_value === "1,4" ? <FaAngleUp /> : <FaAngleDown />}
            </span>
          </th>
          <th
            onClick={() => {
              order_value === "0,3" ? order("1,3") : order("0,3");
            }}
          >
            Client
            <span>
              {order_value === "1,3" ? <FaAngleUp /> : <FaAngleDown />}
            </span>
          </th>
          <th
            onClick={() => {
              order_value === "0,6" ? order("1,6") : order("0,6");
            }}
          >
            FDA
            <span>
              {order_value === "1,6" ? <FaAngleUp /> : <FaAngleDown />}
            </span>
          </th>
          <th
            onClick={() => {
              order_value === "0,5" ? order("1,5") : order("0,5");
            }}
            className="status-th"
          >
            <p>
              Status
              <span>
                {order_value === "1,5" ? <FaAngleUp /> : <FaAngleDown />}
              </span>
            </p>
          </th>
        </tr>
      </thead>
      <tbody>
        {shipments.map((shipment, i) => {
          const isHighlighted = shipment?.mode_of_transport === 40 || shipment?.mode_of_transport === 41;
          return (
            <tr key={i}>
              <td className={`file_no ${isHighlighted ? "highlighted" : ""}`}>
                <NavLink to={`/shipments/edit/${shipment?.id}`}>
                  {shipment?.file_number}
                </NavLink>

                {shipment?.comments && shipment.comments.length > 0 && (
                  <div className="tooltip-container">
                    <FaComment className="tooltip-icon" />
                    <div className="tooltip">
                      {shipment.comments.map((comment, index) => (
                        <div key={index}>{comment.comment}</div>
                      ))}
                    </div>
                  </div>
                )}
              </td>
              <td className={`master_bill ${isHighlighted ? "highlighted" : ""}`}>
                <RenderBillString inputArray={shipment?.master_bill_lading} />
              </td>
              <td className={`house_bill ${isHighlighted ? "highlighted" : ""}`}>
                <RenderBillString inputArray={shipment?.house_bill_lading} />
              </td>
              <td className={`containers ${isHighlighted ? "highlighted" : ""}`}>
                <GetContainers containers={shipment?.containers} />
              </td>
              <td className={`reference_no ${isHighlighted ? "highlighted" : ""}`}>
                <p>{shipment?.customer_reference_no}</p>
              </td>
              <td className={`discharge ${isHighlighted ? "highlighted" : ""}`}>
                <p>{fixedFormat(shipment?.est_time_arrive)}</p>
              </td>
              <td className={`account_no ${isHighlighted ? "highlighted" : ""}`}>
                <p>{shipment?.account_no}</p>
              </td>
              <td className="fda_status">
                {shipment?.fda_review === "MAY PROCEED" || shipment?.fda_review == null ? (
                  <></>
                ) : (
                  <FaExclamation className="fail" />
                )}
              </td>
              <td className={`status ${isHighlighted ? 'highlighted' : ''}`}>
                {statusIcon(shipment.status)}
              </td>
            </tr>
          );
        })}
      </tbody>

    </table>
  );
};

export const LoadingTable = () => {
  return <div className="loading-table"></div>;
};
